import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {isMobileDevice} from "../utils/functions";

@Injectable({
  providedIn: 'root'
})
export class MobileGuardService implements CanActivate {

  constructor(private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (isMobileDevice()) {
      return true;
    }
    this.router.navigate(['']);
    return false;
  }
}
