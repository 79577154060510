import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {
  constructor(
    private translateService: TranslateService,
  ) {
    this.translateService.setDefaultLang('en');

    if (navigator.language.toLowerCase().includes('nl')) {
      this.translateService.use('nl');
    } else {
      this.translateService.use('en')
    }
  }

  ngOnInit(): void {
  }


}
