import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {environment} from "../../../environments/environment";
import {isMobileDevice} from "../utils/functions";
import {DeeplinkService} from "./deeplink.service";

@Injectable({
  providedIn: 'root'
})
export class DesktopGuardService implements CanActivate {

  constructor(
    private router: Router,
    private deeplinkService: DeeplinkService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (isMobileDevice()) {
      this.router.navigate(['/mobile'], {queryParams: {url: state.url}});
      return false;
    }
    if (this.deeplinkService.isValidDeeplink(state.url)) {
      this.router.navigate([''], {queryParams: {url: `${environment.appBaseURL}${state.url}`}});
    }
    else if (state.url != '' && state.url != '/' && !route.queryParams['url']) {
      this.router.navigate(['']);
    }
    return true;
  }
}
