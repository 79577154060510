import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor() { }

  getBearerToken(): string | null {
    return window.localStorage.getItem('userToken');
  }

  setBearerToken(token: string): void {
    window.localStorage.setItem('userToken', token);
  }
}
