import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DesktopGuardService} from "./shared/services/desktop-guard.service";
import {MobileGuardService} from "./shared/services/mobile-guard.service";

const routes: Routes = [
  {
    path: 'mobile',
    loadChildren: () => import('./mobile/mobile.module').then(m => m.MobileModule),
    canActivate: [MobileGuardService]
  },
  {
    path: '**',
    loadChildren: () => import('./desktop/desktop.module').then(m => m.DesktopModule),
    canActivate: [DesktopGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
