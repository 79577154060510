import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {BrowserModule} from '@angular/platform-browser';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpLoaderFactory} from "./shared/utils/functions";
import {ApiInterceptor} from "./shared/services/api-interceptor.service";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
