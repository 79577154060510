import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DeeplinkService {

  constructor() { }

  isValidDeeplink(url: string): boolean {
    return new RegExp('^/archive/download/request/[a-fA-F0-9\-]+$').test(url) ||
           new RegExp('^/archive/download/[a-fA-F0-9\-]+$').test(url) ||
           new RegExp('^/bank-accounts/authorize$').test(url) ||
           new RegExp('^/confirm/email-change/[a-fA-F0-9\-]+$').test(url) ||
           new RegExp('^/confirm/password-reset/[a-fA-F0-9\-]+$').test(url) ||
           new RegExp('^/confirm/register/[a-fA-F0-9\-]+$').test(url) ||
           new RegExp('^/restore/profile/[a-fA-F0-9\-]+$').test(url) ||
           new RegExp('^/subscription.*$').test(url);
  }
}
